import clsx from 'clsx'

type Props = {
  up?: boolean
}

export const ArrowUpIcon = ({ up = true }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.707}
    height={7.561}
    viewBox="0 0 13.707 7.561"
    className={clsx('arrow', {
      up,
      down: !up,
    })}
  >
    <path
      d="M6.5,13,0,6.5,6.5,0"
      transform="translate(13.354 0.707) rotate(90)"
      fill="none"
      stroke="#C2C2C2"
      strokeWidth={2}
    />
  </svg>
)
