import { FC } from 'react'
import t from 'translate'
import { useMergedStyles } from '@/hooks/useMergedStyles'
import { Styles, Translation } from '@/util/util.types'
import { BoxError } from '@/components/BoxError/BoxError'

import styles from './PageLoadingError.module.scss'
import { Logo } from '@/components/Logo/Logo'

type Props = {
  loading: boolean
  error: string
  loadingText?: Translation
  newStyles?: Styles
}

export const PageLoadingError: FC<Props> = ({
  loading,
  loadingText = t('common.getting_data'),
  error,
  newStyles = {},
}) => {
  const mergedStyles = useMergedStyles(styles, newStyles)

  return (
    <div className={mergedStyles['page-loading-error-wrapper']}>
      {loading && (
        <div
          className={mergedStyles['page-loading-loading-error-content-wrapper']}
        >
          <Logo />
          <div
            className={mergedStyles['page-loading-loading-error-content-text']}
          >
            {loadingText}
          </div>
        </div>
      )}
      {error && <BoxError text={error} />}
    </div>
  )
}
