import { components, MenuProps } from 'react-select'
import { PropsWithChildren } from 'react'

type Props = PropsWithChildren<MenuProps>

export const FormFieldSelectMenu = ({ children, ...props }: Props) => (
  <div
    onMouseEnter={() => {
      props.selectProps.customProps.onControlMouseEnter()
    }}
    onMouseLeave={() => {
      props.selectProps.customProps.onControlMouseLeave()
    }}
  >
    <components.Menu {...props}>{children}</components.Menu>
  </div>
)
