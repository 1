import { createIntl, createIntlCache } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import { LanguageLocale, Messages } from './translations.types'
import sr from './languages/sr.json'

export class LangManager {
  public locale: LanguageLocale

  public messages: Messages

  public intl: IntlShape

  constructor() {
    this.locale = 'sr'
    this.messages = sr
    this.intl = createIntl({ locale: 'sr', messages: sr }, createIntlCache())
  }

  async changeLocale(locale: LanguageLocale) {
    const { default: messages } = (await import(
      `./languages/${locale}.json`
    )) as {
      default: Messages
    }

    this.intl = createIntl({ locale, messages }, createIntlCache())
    this.messages = messages
    this.locale = locale
  }
}
