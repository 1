import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { store } from './store/store'
import { PageLoader } from '@/components/PageLoader/PageLoader'
import { initWindowResizeListener } from './util/windowResizeUtils'
import { router } from './router'

import './styles/initialize.scss'

initWindowResizeListener()

const rootElement =
  document.getElementById('root') ?? document.createElement('div')

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} fallbackElement={<PageLoader />} />
    </Provider>
  </React.StrictMode>
)
