import { IntlProvider } from 'react-intl'
import { useEffect, useState, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from '@/store/store'
import langManagerInstance from '@/translations/langManagerInstance'
import { DefaultRichTextElementChunks } from '@/translations/translations.types'
import { selectLanguage } from '@/features/general/state/general.slice.selectors'
import { ScrollToTop } from '@/components/ScrollToTop/ScrollToTop'
import { PageLoader } from '@/components/PageLoader/PageLoader'
import { Header } from '@/components/Header/Header'
import { Footer } from '@/components/Footer/Footer'

import styles from './Root.module.scss'

const defaultRichTextElements = {
  b: (chunks: DefaultRichTextElementChunks) => <b>{chunks}</b>,
  br: () => <br />,
  span: (chunks: DefaultRichTextElementChunks) => <span>{chunks}</span>,
  main: (chunks: DefaultRichTextElementChunks) => (
    <div className="main">{chunks}</div>
  ),
  'secondary-font': (chunks: DefaultRichTextElementChunks) => (
    <div className="secondary-font">{chunks}</div>
  ),
  sfc: (chunks: DefaultRichTextElementChunks) => (
    <div className="secondary-font main">{chunks}</div>
  ),
}

export const Root = () => {
  const language = useAppSelector(selectLanguage)

  const [loadedMessagesLanguage, setLoadedMessagesLanguage] = useState(
    langManagerInstance.locale
  )

  useEffect(() => {
    const changeLocale = async () => {
      await langManagerInstance.changeLocale(language)
      setLoadedMessagesLanguage(language)
    }

    changeLocale().catch((err: unknown) => {
      console.log(err)
    })
  }, [language])

  return (
    <IntlProvider
      onWarn={() => ({})}
      key={langManagerInstance.locale}
      locale={langManagerInstance.locale}
      messages={langManagerInstance.messages}
      // @ts-expect-error: Type issue with react
      defaultRichTextElements={defaultRichTextElements}
    >
      <ScrollToTop>
        <div className={styles.root} key={loadedMessagesLanguage}>
          <div className={styles['root-page-wrapper']}>
            <Header />

            <div className={styles['root-page-content-container']}>
              <div className={styles['root-page-content-wrapper']}>
                <Suspense fallback={<PageLoader text="" />}>
                  <Outlet />
                </Suspense>
              </div>
            </div>
          </div>

          <Footer />
          {/* <SWRegister /> */}
        </div>
      </ScrollToTop>
    </IntlProvider>
  )
}
