import clsx from 'clsx'
import { FC } from 'react'
import { Translation } from '@/util/util.types'
import { IconAlertTriangle } from '@/components/Icons/IconAlertTriangle/IconAlertTriangle'

import styles from './BoxError.module.scss'

type Props = {
  text: Translation
  additionalClass?: string
}

export const BoxError: FC<Props> = ({ text, additionalClass = '' }) => (
  <div
    className={clsx(styles['box-error-wrapper'], {
      [additionalClass]: additionalClass,
    })}
  >
    <div className={styles['box-error-icon-wrapper']}>
      <IconAlertTriangle />
    </div>

    <div className={styles['box-error-contents-wrapper']}>
      <div className={styles['box-error-contents-text']}>{text}</div>
    </div>
  </div>
)
