// import t from 'translate'
import { GroupBase, ValueContainerProps, components } from 'react-select'
import { JSX } from 'react/jsx-runtime'

export const FormFieldSelectCustomValueContainer = (
  props: JSX.IntrinsicAttributes &
    ValueContainerProps<unknown, boolean, GroupBase<unknown>>
) => {
  const item = props.getValue()[0] as {
    label: string
    value: string
    Icon: () => JSX.Element
  } | null
  const Icon = item?.Icon ?? (() => null)

  return (
    <components.ValueContainer {...props}>
      <div className="value-container icon">
        <Icon />
      </div>

      {props.children}
    </components.ValueContainer>
  )
}
