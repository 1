import { FC, JSX, useCallback, useMemo, useRef } from 'react'
import { ErrorMessage, FieldProps } from 'formik'
import clsx from 'clsx'
import Select from 'react-select'
import type { SelectInstance } from 'react-select'
import { SelectOption, Styles } from '@/util/util.types'
import { useMergedStyles } from '@/hooks/useMergedStyles'
import { FormField } from '../FormField/FormField'
import FormFieldSelectCustomDropdownIndicator from './FormFieldSelectCustomDropdownIndicator'
import { FormFieldSelectCustomIconOption } from '@/components/FormFields/FormFieldSelect/FormFieldSelectCustomIconOption'
import { FormFieldSelectCustomValueContainer } from '@/components/FormFields/FormFieldSelect/FormFieldSelectCustomValueContainer'
import { FormFieldSelectControl } from '@/components/FormFields/FormFieldSelect/FormFieldSelectControl'
import { FormFieldSelectMenu } from '@/components/FormFields/FormFieldSelect/FormFieldSelectMenu'

import './select.scss'
import styles from './FormFieldSelect.module.scss'

interface Props extends FieldProps {
  options: SelectOption[]
  onAfterChange: (p: string | null) => void
  labelText?: string
  isMulti?: boolean
  placeholder?: string
  disabled?: boolean
  additionalClassName?: string
  isSearchable?: boolean
  showErrorText?: boolean
  newStyles?: Styles
  isClearable?: boolean
  astrix?: boolean
  additionalSelectClass?: string
  Icon?: () => JSX.Element
  field: FieldProps['field']
  form: FieldProps['form']
  onControlMouseEnter?: () => void
  onControlMouseLeave?: () => void
  hideSelectedOptions?: boolean
}

export const FormFieldSelect: FC<Props> = ({
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  disabled = false,
  additionalClassName = '',
  labelText = '',
  isSearchable = false,
  showErrorText = true,
  newStyles = {},
  onAfterChange = () => {},
  isClearable = false,
  additionalSelectClass = '',
  astrix = false,
  Icon = null,
  onControlMouseEnter = () => {},
  onControlMouseLeave = () => {},
  hideSelectedOptions = false,
}) => {
  const initialMergedStyles = useMergedStyles(styles, newStyles)
  const ref = useRef<SelectInstance | null>(null)
  const { errors, touched, setFieldTouched } = form

  const hasError = touched[field.name as keyof object]
    ? (errors[field.name as keyof object] as string)
    : ''

  const onChange = (newValue: unknown) => {
    const onChangeAsync = async () => {
      if (newValue) {
        await form.setFieldValue(field.name, (newValue as SelectOption).value)
        onAfterChange((newValue as SelectOption).value)
      } else {
        await form.setFieldValue(field.name, null)
        onAfterChange(null)
      }
    }

    onChangeAsync().catch((err: unknown) => {
      console.error(err)
    })
  }

  const value = options.find(
    (option: SelectOption) => option.value === field.value
  )

  const handleLabelClickCallback = useCallback(() => {
    if (ref.current !== null) {
      ref.current.focus()
    }
  }, [])

  const onBlurCallback = () => {
    setFieldTouched(field.name).catch((err: unknown) => {
      console.error(err)
    })
  }

  const variableComponents = useMemo(() => {
    const item = {
      Option: FormFieldSelectCustomIconOption,
      ValueContainer: FormFieldSelectCustomValueContainer,
    }

    return Icon ? item : {}
  }, [Icon])

  return (
    <FormField
      fieldClassName="form-field-select-wrapper"
      disabled={disabled}
      fieldStyles={initialMergedStyles}
      error={hasError}
      additionalClass={additionalClassName}
    >
      {/* @ts-expect-error: Issue with react types */}
      {({ mergedStyles }: { mergedStyles: Styles }) => (
        <div className={mergedStyles['form-field-input-label-wrapper']}>
          {labelText && (
            <div
              className={mergedStyles['form-field-input-label-label-container']}
            >
              <div
                className={mergedStyles['form-field-input-label-label-wrapper']}
              >
                <div
                  className={clsx(
                    mergedStyles['form-field-input-label-label-text'],
                    {
                      [mergedStyles.disabled]: disabled,
                    }
                  )}
                  onClick={handleLabelClickCallback}
                >
                  {labelText}
                  {astrix && (
                    <div
                      className={
                        mergedStyles['form-field-input-label-label-text-astrix']
                      }
                    >
                      *
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <Select
            name={field.name}
            value={value}
            isDisabled={disabled}
            onChange={onChange}
            onBlur={onBlurCallback}
            isClearable={isClearable}
            menuIsOpen
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            hideSelectedOptions={hideSelectedOptions}
            ref={ref}
            customProps={{
              onControlMouseEnter,
              onControlMouseLeave,
            }}
            isSearchable={isSearchable}
            components={{
              DropdownIndicator: FormFieldSelectCustomDropdownIndicator,
              Control: FormFieldSelectControl,
              Menu: FormFieldSelectMenu,
              ...variableComponents,
            }}
            classNamePrefix={additionalSelectClass || 'select'}
            className={mergedStyles['form-field-input-label-input-input']}
          />

          {showErrorText && (
            <ErrorMessage
              name={field.name}
              render={(err) => (
                <div className={mergedStyles['form-field-error']}>{err}</div>
              )}
            />
          )}
        </div>
      )}
    </FormField>
  )
}
