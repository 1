import { GroupBase, OptionProps, components } from 'react-select'
import { JSX } from 'react/jsx-runtime'

export const FormFieldSelectCustomIconOption = (
  props: JSX.IntrinsicAttributes &
    OptionProps<unknown, boolean, GroupBase<unknown>>
) => {
  const { Icon, label } = props.data as {
    label: string
    value: string
    Icon: () => JSX.Element
  }

  return (
    <components.Option {...props}>
      <div className="icon-wrapper">
        <Icon />
      </div>
      <div className="option-value">{label}</div>
    </components.Option>
  )
}
