export const IconAlertTriangle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      fill="#222"
      d="M28 18.667A2.333 2.333 0 0 1 30.331 21v9.333a2.333 2.333 0 1 1-4.666 0V21a2.333 2.333 0 0 1 2.333-2.333ZM28 37.333A2.333 2.333 0 1 0 28 42h.022a2.333 2.333 0 0 0 0-4.667H28Z"
    />
    <path
      fill="#222"
      fillRule="evenodd"
      d="M24.565 5.327a7 7 0 0 1 9.42 2.47l.006.01 19.763 32.994.02.032a7 7 0 0 1-5.986 10.5H8.21a7 7 0 0 1-5.985-10.5l.02-.032L22.007 7.808l2.001 1.199-1.995-1.21a7 7 0 0 1 2.551-2.47Zm1.442 4.885L6.259 43.18a2.334 2.334 0 0 0 1.992 3.487h39.497a2.334 2.334 0 0 0 1.992-3.486L29.994 10.217l-.003-.005a2.333 2.333 0 0 0-3.984 0Z"
      clipRule="evenodd"
    />
  </svg>
)
