import clsx from 'clsx'

import styles from './Logo.module.scss'

type Props = {
  type?: 'white' | 'regular'
}

export const Logo = ({ type = 'regular' }: Props) => (
  <svg
    className={clsx(styles['logo-wrapper'], {
      [styles[type]]: type,
    })}
    width={203}
    height={37}
    viewBox="0 0 203 37"
    fill="none"
  >
    <g id="Group 5">
      <g id="Group 4">
        <path
          id="Path 16"
          d="M26.6289 33.8867C26.757 34.0079 26.8855 34.1288 27.0136 34.25C26.8924 34.1396 26.7643 34.0148 26.6289 33.8867Z"
          fill="white"
        />
        <g id="number">
          <path
            className={styles.number8}
            d="M23.2142 18.8685C26.0174 16.8879 28.8599 13.7961 28.8599 11.0959C28.8599 7.89384 26.3415 5.19727 21.3403 5.19727C16.2323 5.19727 13.9669 9.2971 13.9669 11.8511C13.9669 13.8317 15.1531 15.8442 19.2566 19.4063C18.6154 19.8265 17.9814 20.3037 17.3722 20.8277C17.3722 20.8309 17.3722 20.8309 17.3685 20.8309C17.1515 21.02 16.9342 21.2123 16.7277 21.4152C16.5851 21.5469 16.4461 21.6859 16.3071 21.8249C16.2396 21.896 16.1717 21.9638 16.1042 22.035C16.0364 22.1028 15.9725 22.1739 15.9083 22.2487C15.1245 23.1109 14.4586 24.037 13.9919 24.9845C13.6856 25.6043 13.4683 26.231 13.358 26.8511C13.358 26.8544 13.3543 26.858 13.358 26.8617C13.3434 26.9578 13.3293 27.0504 13.3188 27.1465C13.3115 27.1893 13.3079 27.2358 13.3042 27.2782C13.2901 27.4245 13.2832 27.5704 13.2832 27.7162C13.2832 31.4423 15.8226 34.3417 21.0376 34.4771C20.2433 33.4548 17.3772 30.4574 16.9592 29.1522C16.8714 28.8789 16.6812 28.4503 16.5992 28.0621C16.5208 27.7093 16.4747 27.3388 16.4747 26.9611C16.4747 25.8499 16.781 24.8241 17.3293 23.8907C17.5431 23.5239 18.0954 22.8329 18.0954 22.8329C18.7863 22.0099 19.6663 21.2729 20.6958 20.6281C24.5783 23.8657 26.4518 25.4152 26.4518 27.8233C26.4518 29.3049 25.9213 30.3736 25.0983 31.0896L26.8332 32.7425C28.5961 31.4282 29.6898 29.5082 29.6898 27.4637C29.6898 24.7671 27.1678 22.1776 23.2142 18.8685ZM16.9519 11.2385C16.9519 8.97305 18.5726 7.35605 21.3794 7.35605C24.1827 7.35605 25.8037 9.11893 25.8037 11.8866C25.8037 14.2268 24.3289 15.8086 21.7002 17.643C17.853 14.4406 16.9519 13.2547 16.9519 11.2385Z"
          />
        </g>
        <path
          id="K dole"
          d="M27.2533 34.4681C27.2497 34.4681 27.2461 34.4645 27.2428 34.4608C27.2356 34.4539 27.2283 34.4467 27.2214 34.4394C27.1572 34.3792 27.0897 34.3186 27.0254 34.258C27.0218 34.258 27.0182 34.2543 27.0146 34.2507C26.9543 34.1937 26.8937 34.1368 26.8295 34.0762C26.762 34.012 26.6978 33.9513 26.6299 33.8875C26.4303 33.6988 18.1632 24.8438 18.1632 24.8438C17.6678 25.7691 17.2462 27.0987 17.8384 28.8514C17.9414 29.1566 18.3312 29.702 18.5402 29.9473C19.4822 31.0545 22.4335 34.443 22.4335 34.443L22.9321 35.0023C23.7229 35.8891 24.2501 36.477 24.2501 36.477C27.1281 36.4414 28.9701 36.4306 29.6505 36.5768L29.6897 36.3594C29.1483 36.1101 28.2756 35.4047 27.2533 34.4681Z"
          className={styles['k-part1']}
        />
        <g id="letter">
          <path
            id="K"
            d="M22.4478 0.414062C22.0167 0.48555 20.6135 0.666991 18.8502 0.666991C17.2332 0.666991 16.6203 0.556675 16.0825 0.414062C16.0825 1.09809 15.5411 1.78176 15.1456 2.21287L5.39641 13.0416V4.11872C5.39641 1.85325 6.44006 0.880728 7.41258 0.6278L7.33783 0.414062C6.94229 0.449625 5.5031 0.666991 3.8861 0.666991C2.26547 0.666991 0.466666 0.449625 0.0711248 0.414062L0 0.6278C0.97216 0.880728 2.01254 1.85325 2.01254 4.11872V25.9932C2.01254 28.2583 0.97216 29.2308 0 29.4801L0.0711248 29.6975C0.466666 29.6619 2.26547 29.4482 3.8861 29.4482C5.5031 29.4482 6.94229 29.6619 7.33783 29.6975L7.41258 29.4801C6.44006 29.2308 5.39641 28.2583 5.39641 25.9932V14.4808L13.1439 23.9484C13.6498 23.1504 14.312 22.2102 15.0959 21.3091L9.74917 15.1289L8.18878 13.3544L16.8021 3.94055C18.5618 2.03106 20.9731 1.06217 22.519 0.6278L22.4478 0.414062Z"
            className={styles['k-part2']}
          />
        </g>
        <g id="letters">
          <path
            id="Path 20"
            d="M47.9447 9.94814C47.4435 9.94814 46.7261 9.99277 46.0323 10.036C45.3867 10.0762 44.7771 10.114 44.387 10.114C43.5458 10.114 42.7838 10.024 42.3745 9.97536C42.2736 9.96375 42.1948 9.95467 42.1429 9.94959L41.8566 9.92383L41.5957 10.7069L41.9713 10.8042C42.3973 10.9149 43.0276 11.6109 43.0276 12.7539V27.1756C43.0276 28.6881 42.2892 29.0426 41.9716 29.125L41.5957 29.2219L41.8562 30.005L42.1422 29.9796L42.3276 29.9607C42.75 29.9171 43.7385 29.8152 44.6243 29.8152C45.4622 29.8152 46.2228 29.9048 46.6318 29.9531C46.7352 29.9654 46.8158 29.9748 46.8687 29.9796L47.1547 30.005L47.4156 29.2222L47.0396 29.125C46.7218 29.0426 45.9833 28.6881 45.9833 27.1756V12.4988C46.5113 12.4633 47.1286 12.4292 47.5655 12.4292C49.7776 12.4292 50.8082 13.3614 50.8082 15.3634C50.8082 17.8542 48.821 18.2262 47.6366 18.2262C47.2331 18.2262 46.905 18.1601 46.8143 18.1395L46.6289 18.0985L46.0639 18.6199V20.1222L46.2703 20.221C46.9145 20.5283 47.5288 20.66 48.3243 20.66C50.9642 20.66 53.9057 18.2799 53.9057 14.8652C53.9057 11.8322 51.6217 9.94814 47.9447 9.94814Z"
            className={styles.letter}
          />
          <path
            id="Path 21"
            d="M70.7393 26.437L70.5343 26.1532C70.5343 26.1532 67.489 21.9848 67.3405 21.7805C66.7835 21.0133 66.4032 20.4897 66.0062 20.0956C67.9451 19.3067 69.7269 17.4731 69.7269 14.8393C69.7269 11.8216 67.4792 9.94655 63.8609 9.94655C63.359 9.94655 62.6855 9.99119 62.0341 10.0344C61.4296 10.0743 60.858 10.1124 60.469 10.1124C59.6279 10.1124 58.8658 10.0224 58.4565 9.97377C58.356 9.96216 58.2772 9.95272 58.225 9.948L57.9386 9.92188L57.6777 10.705L58.0533 10.8026C58.4797 10.9133 59.11 11.6096 59.11 12.7524V27.1736C59.11 28.6861 58.3712 29.041 58.0537 29.123L57.6777 29.2206L57.9386 30.0034L58.2246 29.978L58.4097 29.9595C58.8317 29.9156 59.8206 29.8136 60.7064 29.8136C61.5442 29.8136 62.3052 29.9032 62.7138 29.9515C62.8172 29.9638 62.8978 29.9729 62.9508 29.978L63.2364 30.0034L63.4987 29.2206L63.1221 29.123C62.8042 29.041 62.0654 28.6861 62.0654 27.1736V12.4878C62.4101 12.4555 62.7929 12.4276 63.1257 12.4276C65.614 12.4276 66.6293 13.2909 66.6293 15.4086C66.6293 17.362 65.4859 18.438 63.4105 18.438C63.0829 18.438 62.8826 18.3886 62.6441 18.2496L62.0984 17.9321V18.9515L62.2943 19.1942C62.5643 19.4747 63.0513 20.2027 63.9364 21.5243C64.8069 22.8245 66.1224 24.7891 68.0935 27.6182C68.2347 27.8203 68.3563 28.0028 68.4684 28.1719C68.799 28.6694 69.0602 29.0621 69.4928 29.4757L69.5396 29.5204L70.2135 29.8136H70.289C71.9448 29.8136 72.8788 29.917 73.2776 29.9613L73.4351 29.978L73.7701 30.0081L73.8975 29.2417L73.6213 29.1357C72.3149 28.6335 71.4411 27.4153 70.7393 26.437Z"
            className={styles.letter}
          />
          <path
            id="Path 22"
            d="M84.6877 9.94727C78.4436 9.94727 75.0742 15.1325 75.0742 20.0111C75.0742 24.9114 78.6969 29.9801 84.7588 29.9801C91.0029 29.9801 94.3719 24.7953 94.3719 19.9167C94.3719 15.0164 90.7496 9.94727 84.6877 9.94727ZM85.3282 27.547C81.2483 27.547 78.1718 23.9911 78.1718 19.2762C78.1718 15.0875 80.5149 12.3807 84.1419 12.3807C88.2218 12.3807 91.2983 15.9468 91.2983 20.6755C91.2983 24.8497 88.9548 27.547 85.3282 27.547Z"
            className={styles.letter}
          />
          <path
            id="Path 23"
            d="M104.683 9.94814C104.182 9.94814 103.465 9.99277 102.771 10.036C102.125 10.0762 101.516 10.114 101.125 10.114C100.284 10.114 99.522 10.024 99.1127 9.97536C99.0118 9.96375 98.9335 9.95467 98.8812 9.94959L98.5949 9.92383L98.334 10.7069L98.71 10.8042C99.1359 10.9149 99.7663 11.6112 99.7663 12.7539V27.1756C99.7663 28.6881 99.0278 29.0426 98.71 29.125L98.334 29.2222L98.5949 30.005L98.8809 29.9796L99.0659 29.9611C99.4883 29.9171 100.477 29.8152 101.363 29.8152C102.201 29.8152 102.961 29.9048 103.37 29.9531C103.473 29.9654 103.554 29.9748 103.607 29.9796L103.893 30.005L104.155 29.2222L103.778 29.125C103.461 29.0426 102.722 28.6881 102.722 27.1756V12.4988C103.25 12.4633 103.867 12.4292 104.304 12.4292C106.516 12.4292 107.546 13.3614 107.546 15.3634C107.546 17.8542 105.559 18.2262 104.375 18.2262C103.972 18.2262 103.644 18.1601 103.553 18.1395L103.367 18.0985L102.802 18.6199V20.1222L103.009 20.221C103.653 20.5283 104.267 20.66 105.063 20.66C107.703 20.66 110.644 18.2799 110.644 14.8652C110.644 11.8322 108.36 9.94814 104.683 9.94814Z"
            className={styles.letter}
          />
          <path
            id="Path 24"
            d="M125.853 25.9391C125.472 26.3728 124.441 27.3816 123.114 27.3816H119.456C119.274 27.3616 118.804 27.2861 118.804 26.511V20.9205H121.809C122.936 20.9205 123.645 21.7769 123.759 22.2138L123.856 22.5901L124.645 22.3266L124.613 22.0352L124.596 21.9035C124.552 21.5679 124.449 20.7815 124.449 19.6867C124.47 18.5519 124.561 17.7968 124.601 17.4742L124.645 17.0794L123.856 16.8177L123.759 17.193C123.645 17.6302 122.936 18.4866 121.809 18.4866H118.804V12.5829C119.296 12.5673 120.032 12.5477 120.552 12.5477H122.568C124.1 12.5477 124.857 13.3214 124.992 13.841L125.09 14.217L125.873 13.9561L125.847 13.6697C125.843 13.6247 125.834 13.5565 125.823 13.4643C125.767 13.0198 125.635 11.9794 125.635 10.3109V9.87109L125.203 9.95456C124.604 10.0703 123.97 10.0874 123.356 10.1044L123.042 10.1139H117.207C116.366 10.1139 115.604 10.0239 115.195 9.97524C115.094 9.96363 115.016 9.95419 114.963 9.94948L114.677 9.92335L114.416 10.7064L114.792 10.8041C115.218 10.9147 115.848 11.6111 115.848 12.7538V27.1987C115.848 28.3258 115.218 29.0142 114.792 29.1245L114.416 29.2217L114.677 30.0048L114.963 29.9794C115.016 29.9744 115.096 29.9653 115.2 29.953C115.608 29.9047 116.369 29.8151 117.207 29.8151H121.729L122.097 29.8227C122.971 29.8412 123.795 29.8586 124.643 29.9998L124.93 30.0477L125.039 29.7784C125.425 28.8284 126.28 26.8685 126.539 26.5099L126.72 26.2595L126.109 25.6485L125.853 25.9391Z"
            className={styles.letter}
          />
          <path
            id="Path 25"
            d="M143.585 26.4366L143.38 26.1532C143.38 26.1532 140.335 21.9851 140.187 21.7808C139.63 21.0137 139.249 20.4897 138.852 20.0956C140.791 19.3067 142.573 17.4731 142.573 14.8393C142.573 11.8216 140.325 9.94655 136.707 9.94655C136.205 9.94655 135.531 9.99119 134.88 10.0344C134.275 10.0743 133.704 10.1124 133.315 10.1124C132.474 10.1124 131.712 10.0224 131.302 9.97377C131.201 9.96216 131.123 9.95272 131.071 9.948L130.784 9.92188L130.523 10.705L130.899 10.8026C131.325 10.9133 131.956 11.6093 131.956 12.7524V27.1736C131.956 28.6861 131.217 29.041 130.899 29.123L130.523 29.2203L130.784 30.0034L131.07 29.978L131.255 29.9591C131.677 29.9156 132.667 29.8136 133.552 29.8136C134.39 29.8136 135.151 29.9032 135.56 29.9515C135.663 29.9638 135.744 29.9729 135.797 29.978L136.082 30.0034L136.345 29.2206L135.968 29.123C135.65 29.041 134.911 28.6861 134.911 27.1736V12.4878C135.256 12.4555 135.639 12.4276 135.971 12.4276C138.46 12.4276 139.475 13.2909 139.475 15.4086C139.475 17.362 138.332 18.438 136.256 18.438C135.929 18.438 135.729 18.3886 135.49 18.2496L134.944 17.9318V18.9515L135.14 19.1942C135.41 19.4747 135.897 20.2023 136.781 21.5232C137.652 22.8234 138.968 24.7884 140.94 27.6182C141.08 27.8203 141.202 28.0032 141.314 28.1726C141.645 28.6698 141.906 29.0624 142.339 29.4757L142.385 29.5204L143.059 29.8136H143.135C144.791 29.8136 145.725 29.917 146.123 29.9613L146.281 29.978L146.616 30.0081L146.743 29.2417L146.467 29.1354C145.161 28.6335 144.287 27.4153 143.585 26.4366Z"
            className={styles.letter}
          />
          <path
            id="Path 26"
            d="M162.706 13.381C162.65 12.9753 162.521 12.0245 162.521 10.3803V9.95898L162.104 10.0214C161.542 10.1063 160.659 10.1125 160.402 10.1125H149.254C148.587 10.1125 147.95 10.0784 147.551 10.021L147.136 9.96189V10.3803C147.136 12.0249 147.007 12.9756 146.951 13.3813C146.938 13.4775 146.929 13.549 146.924 13.5976L146.898 13.8835L147.682 14.1448L147.78 13.7685C147.914 13.2489 148.671 12.4752 150.203 12.4752H152.219C152.436 12.4752 152.67 12.481 152.909 12.4861C153.047 12.4897 153.187 12.493 153.327 12.4952V27.1741C153.327 28.6866 152.588 29.0411 152.271 29.1235L151.895 29.2207L152.156 30.0038L152.441 29.9781L152.627 29.9599C153.049 29.9157 154.038 29.8137 154.923 29.8137C155.761 29.8137 156.522 29.9033 156.931 29.9516C157.034 29.9639 157.115 29.9734 157.168 29.9781L157.454 30.0038L157.715 29.2204L157.339 29.1235C157.021 29.0411 156.282 28.6866 156.282 27.1741V12.4955C156.423 12.493 156.565 12.4897 156.705 12.4865C156.957 12.481 157.206 12.4752 157.438 12.4752H159.454C160.986 12.4752 161.742 13.2489 161.877 13.7685L161.975 14.1445L162.759 13.8835L162.733 13.5976C162.728 13.5486 162.719 13.4775 162.706 13.381Z"
            className={styles.letter}
          />
          <path
            id="Path 27"
            d="M170.206 27.1756V12.7543C170.206 11.2418 170.945 10.8869 171.263 10.8045L171.639 10.7066L171.377 9.92383L171.091 9.94959C171.039 9.95467 170.96 9.96375 170.859 9.97572C170.45 10.024 169.688 10.114 168.847 10.114C167.955 10.114 166.965 10.0113 166.542 9.96737L166.079 9.92383L165.818 10.7069L166.194 10.8045C166.511 10.8869 167.25 11.2422 167.25 12.7543V27.1756C167.25 28.6881 166.511 29.043 166.194 29.125L165.818 29.2222L166.079 30.0053L166.365 29.9799L166.55 29.9611C166.972 29.9175 167.961 29.8152 168.847 29.8152C169.685 29.8152 170.445 29.9048 170.854 29.9531C170.957 29.9654 171.038 29.9748 171.091 29.9799L171.377 30.0053L171.639 29.2226L171.262 29.125C170.945 29.043 170.206 28.6881 170.206 27.1756Z"
            className={styles.letter}
          />
          <path
            id="Path 28"
            d="M187.598 25.9391C187.216 26.3728 186.185 27.3816 184.858 27.3816H181.201C181.019 27.3616 180.548 27.2861 180.548 26.511V20.9205H183.553C184.68 20.9205 185.39 21.7769 185.503 22.2138L185.601 22.5901L186.389 22.3266L186.357 22.0352L186.34 21.9035C186.297 21.5679 186.193 20.7815 186.193 19.6867C186.214 18.5519 186.306 17.7968 186.345 17.4742L186.389 17.0794L185.601 16.8177L185.503 17.193C185.39 17.6302 184.68 18.4866 183.553 18.4866H180.548V12.5829C181.041 12.5673 181.776 12.5477 182.297 12.5477H184.312C185.844 12.5477 186.601 13.3214 186.736 13.841L186.834 14.2173L187.617 13.9557L187.591 13.6694C187.587 13.6247 187.579 13.5576 187.567 13.4669C187.511 13.0227 187.379 11.9827 187.379 10.3109V9.87109L186.948 9.95456C186.349 10.0703 185.714 10.0874 185.1 10.1044L184.787 10.1139H178.952C178.11 10.1139 177.349 10.0239 176.939 9.97524C176.838 9.96363 176.76 9.95419 176.707 9.94948L176.421 9.92335L176.16 10.7064L176.536 10.8041C176.962 10.9147 177.592 11.6107 177.592 12.7538V27.1987C177.592 28.3258 176.962 29.0142 176.536 29.1245L176.16 29.2217L176.421 30.0048L176.707 29.9794C176.76 29.9744 176.841 29.9653 176.944 29.953C177.353 29.9047 178.114 29.8151 178.952 29.8151H183.474L183.841 29.8227C184.715 29.8412 185.54 29.8586 186.387 29.9998L186.674 30.0477L186.783 29.7788C187.17 28.8269 188.026 26.8656 188.283 26.5099L188.464 26.2595L187.854 25.6485L187.598 25.9391Z"
            className={styles.letter}
          />
          <path
            id="Path 29"
            d="M197.868 18.3496C195.408 16.4201 194.876 15.7296 194.876 14.4609C194.876 13.4873 195.647 12.452 197.075 12.452C199.837 12.452 200.595 14.2835 200.672 14.4921L200.794 14.8213L201.56 14.5658L201.513 14.2632C201.282 12.7568 201.287 11.0419 201.436 10.4453L201.517 10.1187L200.805 9.88281L200.649 10.0955C200.579 10.1913 200.477 10.2798 200.217 10.2798C200.114 10.2798 199.993 10.2664 199.833 10.2359C199.726 10.2199 199.615 10.1956 199.49 10.1688C199.053 10.0755 198.456 9.9474 197.336 9.9474C193.931 9.9474 191.897 12.4596 191.897 14.888C191.897 17.1528 193.753 18.8061 195.996 20.5809C198.106 22.2462 199.511 23.6458 199.511 25.0632C199.511 26.6795 198.635 27.4993 196.926 27.4996C194.623 27.39 193.803 25.953 192.971 24.0856L192.837 23.7852L192.074 24.0395L192.142 24.3574C192.458 25.8209 192.083 28.8778 191.932 29.482L191.85 29.8086L192.598 30.0568L192.744 29.7923C192.963 29.3931 193.119 29.2447 193.318 29.2447C193.373 29.2447 193.436 29.2556 193.489 29.2716C193.648 29.3285 193.796 29.3862 193.94 29.4425C194.646 29.719 195.314 29.9803 196.79 29.9803C200.271 29.9803 202.609 27.3766 202.609 24.9449C202.609 22.5281 200.943 20.762 197.868 18.3496Z"
            className={styles.letter}
          />
        </g>
      </g>
    </g>
  </svg>
)
