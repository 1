import { components, DropdownIndicatorProps } from 'react-select'
import { ArrowUpIcon } from '@/components/Icons/ArrowUpIcon'

const FormFieldSelectCustomDropdownIndicator = (
  props: DropdownIndicatorProps
) => (
  <components.DropdownIndicator {...props}>
    <ArrowUpIcon up />
  </components.DropdownIndicator>
)

export default FormFieldSelectCustomDropdownIndicator
