import { useLocation } from 'react-router-dom'
import translate from 'translate'
import { LogoFull } from '@/components/LogoFull/LogoFull'
import {
  COPYRIGHT_TEXT,
  ROUTES_NO_T,
} from '@/features/general/general.constants'

import styles from './Footer.module.scss'
import { ContactItems } from '@/components/ContactItems/ContactItems'
import { PageLinks } from '@/components/PageLinks/PageLinks'

export const Footer = () => {
  const location = useLocation()

  if (
    location.pathname.includes(ROUTES_NO_T.contact.to) ||
    location.pathname === ROUTES_NO_T.home.to
  ) {
    return null
  }

  return (
    <div className={styles['footer-wrapper']}>
      <div className={styles['footer-content-container']}>
        <div className={styles['footer-content-top-wrapper']}>
          <LogoFull />

          <div className={styles['footer-content-top-sections-wrapper']}>
            <ContactItems />
            <PageLinks />
          </div>
        </div>

        <div className={styles['footer-content-bottom-wrapper']}>
          <div className={styles['footer-content-bottom-tagline-wrapper']}>
            {translate('common.tagline')}
          </div>

          <div className={styles['footer-content-bottom-copyright-wrapper']}>
            {COPYRIGHT_TEXT}
          </div>
        </div>
      </div>
    </div>
  )
}
