import { ROUTES } from '@/features/general/general.constants'
import { PageLinkRouteKeys } from './PageLinks.types'

export const PAGES_LINK_ROUTE_KEYS: PageLinkRouteKeys[] = [
  'home',
  'aboutUs',
  'projects',
  'news',
  'greenConstruction',
  'privacyPolicy',
  'cookiePolicy',
  'contact',
]

export const PAGES_LINKS = () =>
  PAGES_LINK_ROUTE_KEYS.map((key) => ROUTES()[key])
