import { useLocation } from 'react-router-dom'
import { FC, ReactElement, useLayoutEffect } from 'react'

type Props = {
  children: ReactElement | null
}

export const ScrollToTop: FC<Props> = ({ children }) => {
  const location = useLocation()

  useLayoutEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  }, [location.pathname])

  return children
}
