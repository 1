import { FC } from 'react'
import clsx from 'clsx'
import t from 'translate'
import { Translation } from '@/util/util.types'
import { PageLoadingError } from '@/components/PageLoadingError/PageLoadingError'

import styles from './PageLoader.module.scss'

type Props = {
  text?: Translation
  nested?: boolean
}

export const PageLoader: FC<Props> = ({
  text = t('page_loader.loading_page'),
  nested = false,
}) => (
  <div
    className={clsx(styles['page-loader-wrapper'], { [styles.nested]: nested })}
  >
    <PageLoadingError loading loadingText={text} error="" />
  </div>
)
