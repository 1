import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit'
import { RootState, AppDispatch } from './storeTypes'
import { startGeneralListeners } from '@/features/general/state/general.slice.listeners'

export const listenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening

startGeneralListeners()
