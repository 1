import { NavLink, Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Logo } from '@/components/Logo/Logo'
import { useTranslateConstant } from '@/hooks/useTranslateConstant'
import { HeaderLanguageForm } from '@/components/Header/HeaderLanguageForm/HeaderLanguageForm'
import { useAppSelector } from '@/store/store'
import { selectLanguage } from '@/features/general/state/general.slice.selectors'
import { HEADER_LINKS } from './Header.constants'
import { usePageType } from '@/hooks/usePageType'

import styles from './Header.module.scss'
import { ROUTES_NO_T } from '@/features/general/general.constants'

export const Header = () => {
  const location = useLocation()
  const language = useAppSelector(selectLanguage)
  const type = usePageType()
  const HEADER_LINKS_TRANSLATED = useTranslateConstant(HEADER_LINKS)

  const showJustLang = useMemo(
    () => location.pathname === ROUTES_NO_T.home.to,
    [location.pathname]
  )

  return (
    <nav
      className={clsx(styles['header-wrapper'], {
        [styles['just-lang']]: showJustLang,
      })}
    >
      <div className={styles['header-content-wrapper']}>
        {!showJustLang && (
          <>
            <Link to="/">
              <Logo type={type} />
            </Link>

            <div className={styles['header-content-links-wrapper']}>
              {HEADER_LINKS_TRANSLATED.map(({ to, text }) => (
                <NavLink
                  to={to}
                  key={to}
                  className={clsx(styles['header-content-links-link'], {
                    [styles[type]]: type,
                  })}
                >
                  {({ isActive }) => (
                    <>
                      {text}
                      {isActive && (
                        <div
                          className={styles['header-content-links-link-border']}
                        />
                      )}
                    </>
                  )}
                </NavLink>
              ))}
            </div>
          </>
        )}

        <HeaderLanguageForm type={type} initialValues={{ language }} />
      </div>
    </nav>
  )
}
