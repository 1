import { Field, Form, withFormik } from 'formik'
import { useCallback, useState } from 'react'
import clsx from 'clsx'
import type {
  HeaderLanguageFormProps,
  HeaderLanguageFormValues,
} from './HeaderLanguageForm.types'
import { FormFieldSelect } from '@/components/FormFields/FormFieldSelect/FormFieldSelect'
import { useAppDispatch } from '@/store/store'
import { setLanguage } from '@/features/general/state/general.slice'
import {
  LANGUAGE_LS_KEY,
  LANGUAGE_SELECT_OPTIONS,
} from '@/features/general/general.constants'
import { LanguageLocale } from '@/translations/translations.types'

import './HeaderLanguageForm.scss'
import styles from './HeaderLanguageForm.module.scss'

export const HeaderLanguageFormInner = ({ type }: HeaderLanguageFormProps) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const dispatch = useAppDispatch()

  const onLanguageChangeCallback = useCallback(
    (newLang: LanguageLocale) => {
      dispatch(setLanguage(newLang))
      localStorage.setItem(LANGUAGE_LS_KEY, newLang)

      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    },
    [dispatch]
  )

  return (
    <Form
      id="HeaderLanguageForm"
      className={styles['header-language-form-wrapper']}
    >
      <div
        className={clsx(styles['header-language-form-divider'], {
          [styles[type]]: type,
        })}
      />

      <div
        className={clsx('header-form-select-wrapper', { open: menuVisible })}
      >
        <Field
          name="language"
          id="language"
          component={FormFieldSelect}
          options={LANGUAGE_SELECT_OPTIONS}
          newStyles={styles}
          additionalSelectClass={`header-form-select-${type}`}
          onAfterChange={onLanguageChangeCallback}
          hideSelectedOptions
          onControlMouseEnter={() => {
            setMenuVisible(true)
          }}
          onControlMouseLeave={() => {
            setMenuVisible(false)
          }}
        />
      </div>
    </Form>
  )
}

export const HeaderLanguageForm = withFormik<
  HeaderLanguageFormProps,
  HeaderLanguageFormValues
>({
  mapPropsToValues: ({ initialValues }) => ({
    language: initialValues.language,
  }),
  handleSubmit: () => {},
})(HeaderLanguageFormInner)
