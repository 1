import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { GlobalState } from './globalStateTypes'
import { listenerMiddleware } from './listenerMiddleware'
import { AppDispatch } from '@/store/storeTypes'
import { generalSlice } from '@/features/general/state/general.slice'

export const store = configureStore({
  reducer: {
    general: generalSlice.reducer,
  },
  middleware: (getDefaultM) => {
    const res = getDefaultM({ serializableCheck: false })
    return res.prepend(listenerMiddleware.middleware)
  },
})

export const useAppSelector: TypedUseSelectorHook<GlobalState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch

export const useGlobalStateSelector = () => useAppSelector((state) => state)
