import { useMemo } from 'react'
import type { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { Styles } from '@/util/util.types'
import { useMergedStyles } from '@/hooks/useMergedStyles'

import styles from './FormField.module.scss'

type Props = PropsWithChildren<{
  fieldStyles: Styles
  fieldClassName: string
  disabled?: boolean
  error?: string
  additionalClass?: string
}>

export const FormField = ({
  fieldStyles,
  fieldClassName = '',
  disabled = false,
  error = '',
  additionalClass = '',

  children,
}: Props) => {
  const mergedStyles = useMergedStyles(styles, fieldStyles)

  const fieldClassNames = useMemo(
    () =>
      fieldClassName.split(',').reduce(
        (combined, className) => ({
          ...combined,
          [mergedStyles[className]]: true,
        }),
        {}
      ),
    [mergedStyles, fieldClassName]
  )

  return (
    <div
      className={clsx(mergedStyles['form-field-wrapper'], {
        ...fieldClassNames,
        [mergedStyles.disabled]: disabled,
        [mergedStyles.error]: error,
        [additionalClass]: additionalClass,
      })}
    >
      {/* @ts-expect-error: React types issue */}
      {children({ mergedStyles })}
    </div>
  )
}
