import { Link } from 'react-router-dom'
import { useTranslateConstant } from '@/hooks/useTranslateConstant'
import { PAGES_LINKS } from './PageLinks.constants'

import styles from './PageLinks.module.scss'

export const PageLinks = () => {
  const pagesLinks = useTranslateConstant(PAGES_LINKS)

  return (
    <div className={styles['page-links-wrapper']}>
      {pagesLinks.map((item) => (
        <Link
          to={item.to}
          key={item.to}
          className={styles['page-links-link-wrapper']}
        >
          {item.text}
        </Link>
      ))}
    </div>
  )
}
