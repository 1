import translate from 'translate'
import { Translation } from '@/util/util.types'

export const CONTACT_ITEMS = (): {
  id: string
  title: Translation
  items: { id: string; label: Translation | string; bold?: true }[]
}[] => [
  {
    id: 'address',
    title: translate('common.address'),
    items: [
      { id: 'address1', label: 'K8 Properties d.o.o.', bold: true },
      { id: 'address2', label: 'Generala Vasića 1, 11 000 Beograd, Srbija' },
    ],
  },
  {
    id: 'mail',
    title: translate('common.mail'),
    items: [{ id: 'mail1', label: 'office@k8.rs' }],
  },
  {
    id: 'phone-number',
    title: translate('common.phone_number'),
    items: [
      { id: 'phone-number1', label: '+381 11 6188 136' },
      { id: 'phone-number2', label: '+381 69 3838 388' },
    ],
  },
  {
    id: 'work-times',
    title: translate('common.work_times'),
    items: [
      {
        id: 'work-times1',
        label: translate('contact.work_times_from_to'),
        bold: true,
      },
      { id: 'work-times2', label: translate('contact.work_times_hours') },
    ],
  },
]
