import translate from 'translate'
import type { LanguageLocale } from '@/translations/translations.types'
import { RouteKey, Routes } from '@/features/general/general.types'

export const ROUTE_KEYS: RouteKey[] = [
  'home',
  'aboutUs',
  'projects',
  'news',
  'greenConstruction',
  'cookiePolicy',
  'privacyPolicy',
  'contact',
  'page404',
]

export const ROUTES: () => Routes = () => ({
  home: { to: '/', text: translate('common.home') },
  aboutUs: { to: '/o-nama', text: translate('common.about_us') },
  projects: { to: '/projekti', text: translate('common.projects') },
  news: { to: '/vesti', text: translate('common.news') },
  newsSingle: {
    to: '/vesti/vest/:id',
    createTo: (id: string) => `/vesti/vest/${id}`,
    text: translate('common.news'),
  },
  greenConstruction: {
    to: '/zelena-gradnja',
    text: translate('common.green_construction'),
  },
  privacyPolicy: {
    to: '/politika-privatnosti',
    text: translate('common.privacy_policy'),
  },
  cookiePolicy: {
    to: '/politika-kolacica',
    text: translate('common.cookie_policy'),
  },
  contact: { to: '/kontakt', text: translate('common.contact') },
  page404: { to: '/404', text: '404' },
})

export const ROUTES_NO_T = ROUTES()

export const LANGUAGE_SELECT_OPTIONS: {
  label: string
  value: LanguageLocale
}[] = [
  { label: 'SRB', value: 'sr' },
  { label: 'ENG', value: 'en' },
  { label: 'RU', value: 'ru' },
]

export const LANGUAGE_LS_KEY = 'language-locale'

export const COPYRIGHT_TEXT = '© 2024 K8 PROPERTIES. All Rights Reserved.'
