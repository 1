import { components, ControlProps } from 'react-select'
import { PropsWithChildren } from 'react'

type Props = PropsWithChildren<ControlProps>

export const FormFieldSelectControl = ({ children, ...props }: Props) => (
  <div
    onMouseEnter={() => {
      props.selectProps.customProps.onControlMouseEnter()
    }}
    onMouseLeave={() => {
      props.selectProps.customProps.onControlMouseLeave()
    }}
  >
    <components.Control {...props}>{children}</components.Control>
  </div>
)
