import { lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { Root } from '@/pages/Root'
import { ROUTES } from '@/features/general/general.constants'

const Home = lazy(() => import('./pages/Home/Home'))
const Page404 = lazy(() => import('./pages/Page404/Page404'))
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'))
const CookiePolicy = lazy(() => import('./pages/CookiePolicy/CookiePolicy'))
const GreenConstruction = lazy(
  () => import('./pages/GreenConstruction/GreenConstruction')
)
const Projects = lazy(() => import('./pages/Projects/Projects'))
const Contact = lazy(() => import('./pages/Contact/Contact'))
const News = lazy(() => import('./pages/News/News'))
const NewsSingle = lazy(() => import('./pages/News/NewsSingle/NewsSingle'))

const routes = ROUTES()

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { path: '', element: <Home /> },
      { path: routes.aboutUs.to, element: <AboutUs /> },
      { path: routes.projects.to, element: <Projects /> },
      { path: routes.greenConstruction.to, element: <GreenConstruction /> },
      { path: routes.contact.to, element: <Contact /> },
      { path: routes.privacyPolicy.to, element: <PrivacyPolicy /> },
      { path: routes.cookiePolicy.to, element: <CookiePolicy /> },
      { path: routes.news.to, element: <News /> },
      { path: routes.newsSingle.to, element: <NewsSingle /> },

      { path: '*', element: <Navigate to={routes.page404.to} /> },
      { path: routes.page404.to, element: <Page404 /> },
    ],
  },
])
