export const parseJSON = (input: string): unknown => JSON.parse(input)

export const wait = (time = 500, val?: true) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(val)
    }, time)
  })

export const errorToString = (error: unknown) => {
  if (typeof error === 'string') return error
  if (error instanceof Error) return error.message
  if (error instanceof Object) return JSON.stringify(error)
  return 'Unknown error'
}

/**
 * Checks if the passed string is a JSON object
 *
 * @param str {String}
 * @return {boolean}
 * @constructor
 */
export const isJsonString = (str: string) => {
  try {
    parseJSON(str)
  } catch (error) {
    console.error(error)
    return false
  }

  return true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  waitFor: number
) => {
  let timeout = 0

  return (...args: Parameters<T>): ReturnType<T> => {
    let result: never

    window.clearTimeout(timeout)

    timeout = window.setTimeout(() => {
      // @ts-expect-error: Unsafe assignment
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      result = callback(...args)
    }, waitFor)

    // @ts-expect-error: Returned before initialized
    return result
  }
}

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const formattedNumber = (num: number) =>
  num.toFixed(2).replace(/[.,]00$/, '')
