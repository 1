import { translateWrapper } from './translateWrapper'
import langManagerInstance from './langManagerInstance'
import { FormattedMessageValue } from './translations.types'

const translate = (
  idParam: string,
  values: FormattedMessageValue = {},
  tagName = ''
) => translateWrapper(idParam, values, tagName, langManagerInstance)

// @ts-expect-error: Duplicate export in vite-env.d.ts
export default translate
