import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { PageType } from '@/features/general/general.types'
import { ROUTES } from '@/features/general/general.constants'

export const usePageType = () => {
  const location = useLocation()

  return useMemo<PageType>(() => {
    const routes = ROUTES()
    const regularPathnames = [
      routes.news.to,
      routes.cookiePolicy.to,
      routes.privacyPolicy.to,
      routes.page404.to,
    ]
    const isRegular = regularPathnames.some((path) =>
      location.pathname.includes(path)
    )

    return isRegular ? 'regular' : 'white'
  }, [location.pathname])
}
