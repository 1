/**
 * Sets the window values as variables in order to
 * better track window size
 */
const setWindowVariables = () => {
  const { innerWidth: width, innerHeight: height } = window

  const vhInitial = height * 0.01
  const vwInitial = width * 0.01
  document.documentElement.style.setProperty(
    '--vh',
    `${vhInitial.toString()}px`
  )
  document.documentElement.style.setProperty(
    '--vw',
    `${vwInitial.toString()}px`
  )
  document.documentElement.style.setProperty('--height', height.toString())
  document.documentElement.style.setProperty('--width', width.toString())
}

/**
 * Listens to window resize and adds initial window variables
 */
export const initWindowResizeListener = () => {
  setWindowVariables()

  window.addEventListener('resize', () => {
    setWindowVariables()
  })
}
