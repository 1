import clsx from 'clsx'
import { useTranslateConstant } from '@/hooks/useTranslateConstant'
import { CONTACT_ITEMS } from './ContactItems.contstants'

import styles from './ContactItems.module.scss'

type Props = {
  alignment?: 'left' | 'right'
}

export const ContactItems = ({ alignment = 'left' }: Props) => {
  const contactItems = useTranslateConstant(CONTACT_ITEMS)

  return (
    <div
      className={clsx(styles['contact-items-wrapper'], {
        [styles[alignment]]: alignment,
      })}
    >
      {contactItems.map((contactItem) => (
        <div
          key={contactItem.id}
          className={styles['contact-items-item-container']}
        >
          <div className={styles['contact-items-item-title']}>
            {contactItem.title}
          </div>

          <div className={styles['contact-items-item-subitems-wrapper']}>
            {contactItem.items.map((subItem) => (
              <div
                className={clsx(
                  styles['contact-items-item-subitems-subitem-wrapper'],
                  { [styles.bold]: subItem.bold }
                )}
                key={subItem.id}
              >
                {subItem.label}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
