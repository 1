import { useMemo } from 'react'
import { Styles } from '@/util/util.types'

export const useMergedStyles = (
  currentStyles: Styles,
  newStyles: Styles
): Styles =>
  useMemo(() => {
    const mergedClasses: Styles = Object.keys(currentStyles).reduce(
      (acc, className) => {
        if (newStyles[className]) {
          return {
            ...acc,
            [className]: `${currentStyles[className]} ${newStyles[className]}`,
          }
        }

        return { ...acc, [className]: currentStyles[className] }
      },
      {}
    )

    const nonMergedClasses: Styles = Object.keys(newStyles).reduce(
      (acc, className) => {
        if (!mergedClasses[className]) return { ...acc, [className]: newStyles[className] }

        return acc
      },
      {}
    )

    return { ...mergedClasses, ...nonMergedClasses }
  }, [currentStyles, newStyles])
