import { FormattedMessage as IntlFormattedMessage } from 'react-intl'
import { FormattedMessageValue } from './translations.types'
import { LangManager } from './langManager'

const HTML_REGEX = /<\/?[\w\s="/.':;#-/]+>/i

/**
 * Takes a missing translation id and transforms it to a readable format
 * e.g. Common.read_more_here -> Read more here
 *
 * @param id {String}
 * @return {string}
 */
const fallbackDefaultMessage = (id: string) => {
  const withoutDots = id.substring(id.lastIndexOf('.') + 1, id.length)
  const withoutUnderscore = withoutDots.replace(/_/g, ' ')
  return withoutUnderscore.charAt(0).toUpperCase() + withoutUnderscore.slice(1)
}

export const translateWrapper = (
  idParam: string,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  values: FormattedMessageValue = {},
  tagName: string,
  langManager: LangManager
) => {
  // if (!langManager.intl) return '';

  let id = idParam
  let defaultMessage = langManager.messages[id]

  if (!id) {
    id = 'empty-id'
    defaultMessage = ''
  } else if (id && !defaultMessage) {
    defaultMessage = fallbackDefaultMessage(id)
  }
  // if (id === '') {
  //
  // }

  const defaultMessageHasHtml = HTML_REGEX.test(defaultMessage)

  // Return JSX element
  // The default html element values for this is in index.js
  if (tagName || defaultMessageHasHtml) {
    const propsForIntlFormattedMessage = { id, defaultMessage, values: {} }
    if (Object.keys(values).length > 0) {
      propsForIntlFormattedMessage.values = values
    }
    // if (tagName) propsForIntlFormattedMessage.tagName = tagName;

    // The spread is used to skip the react-intl static analysis syntax
    // because that functionality is replaced and extended by our scripts
    // that check translation files and translation ids usage in components
    return <IntlFormattedMessage {...propsForIntlFormattedMessage} />
  }

  // Returns just strings
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return langManager.intl.formatMessage({ id, defaultMessage }, values)
}
