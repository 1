import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalState } from '@/store/globalStateTypes'
import { LanguageLocale } from '@/translations/translations.types'
import { LANGUAGE_LS_KEY } from '@/features/general/general.constants'
import { ContactFormValues } from '@/pages/Contact/ContactForm/ContactForm.types'
import { ActionErrorPayload, Merge } from '@/util/util.types'

const initialState: GlobalState['general'] = {
  language: (localStorage.getItem(LANGUAGE_LS_KEY) ?? 'sr') as LanguageLocale,
  contactRequest: { status: 'idle' },
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageLocale>) => ({
      ...state,
      language: action.payload,
    }),

    // CONTACT
    contact: (state, action: PayloadAction<ContactFormValues>) => {
      if (state.contactRequest.status !== 'idle') return state

      return {
        ...state,
        contactRequest: { status: 'in-progress', data: action.payload },
      }
    },
    contactSuccess: (
      state,
      action: PayloadAction<{ email: ContactFormValues['email'] }>
    ) => {
      if (state.contactRequest.status !== 'in-progress') return state

      return {
        ...state,
        contactRequest: { status: 'success', email: action.payload.email },
      }
    },
    contactFailed: (
      state,
      action: PayloadAction<
        Merge<ActionErrorPayload, { data: ContactFormValues }>
      >
    ) => {
      if (state.contactRequest.status !== 'in-progress') return state

      return {
        ...state,
        contactRequest: {
          status: 'error',
          error: action.payload.error,
          data: action.payload.data,
        },
      }
    },
    contactClearError: (state) => {
      if (state.contactRequest.status !== 'error') return state

      return { ...state, contactRequest: { status: 'idle' } }
    },
  },
})

export const {
  setLanguage,
  contact,
  contactSuccess,
  contactFailed,
  contactClearError,
} = generalSlice.actions
