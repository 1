import { startAppListening } from '@/store/listenerMiddleware'
import { errorToString } from '@/util/utilService'
import {
  contact as contactAction,
  contactFailed,
  contactSuccess,
} from '@/features/general/state/general.slice'
import { contact } from '@/features/general/general.api'

export function startGeneralListeners() {
  startAppListening({
    actionCreator: contactAction,
    effect: async (_action, props) => {
      const { dispatch } = props
      const { general } = props.getState()

      if (general.contactRequest.status !== 'in-progress') return

      try {
        await contact(general.contactRequest.data)

        dispatch(contactSuccess({ email: general.contactRequest.data.email }))
      } catch (error) {
        dispatch(
          contactFailed({ error: errorToString(error), data: _action.payload })
        )
      }
    },
  })
}
