import { HeaderLinkRouteKeys } from '@/components/Header/Header.types'
import { ROUTES } from '@/features/general/general.constants'

export const HEADER_LINK_ROUTE_KEYS: HeaderLinkRouteKeys[] = [
  'home',
  'aboutUs',
  'projects',
  'news',
  'greenConstruction',
  'contact',
]

export const HEADER_LINKS = () => HEADER_LINK_ROUTE_KEYS.map((key) => ROUTES()[key])
